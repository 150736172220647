<template>
  <mdb-tabs
    :active="0"
    default
    justify
    :links="[
      { text: $t('rulesandpolicy.rules') },
      { text: $t('rulesandpolicy.policy') },
    ]"
    :content="[content.rules, content.policy]"
  />
</template>

<script>
import { mdbTabs } from "mdbvue";
export default {
  name: "MainHeader",
  components: {
    mdbTabs,
  },
  props: {
    content: Object,
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>
<style scoped>
p {
  font-size: 14px !important;
}
</style>

<template>
  <div>
    <mdb-row class="justify-content-center">
      <mdb-col col="6" class="m-0">
        <h6 class="text-center">{{ $t("basicform.form_title") }}</h6>
      </mdb-col>
    </mdb-row>
    <mdb-row class="justify-content-center">
      <mdb-col col="7" class="m-0">
        <mdb-input
          type="text"
          outline
          inputClass="z-depth-1 p-3"
          v-model="txObject.email"
          :label="$t('basicform.email')"
        />
      </mdb-col>
    </mdb-row>
    <mdb-row class="justify-content-center">
      <mdb-col col="7">
        <mdb-input
          type="text"
          outline
          inputClass="z-depth-1 p-3"
          v-model="txObject.firstname"
          :label="$t('basicform.first_name')"
        />
      </mdb-col>
    </mdb-row>
    <mdb-row class="justify-content-center">
      <mdb-col col="7">
        <mdb-input
          type="text"
          outline
          inputClass="z-depth-1 p-3"
          v-model="txObject.lastname"
          :label="$t('basicform.last_name')"
        />
      </mdb-col>
    </mdb-row>
    <mdb-row class="justify-content-center mt-2">
      <mdb-col col="2">
        <mdb-input
          type="checkbox"
          id="checkbox2"
          name="check2"
          v-model="check2"
        />
      </mdb-col>
      <mdb-col col="5">
        <p v-html="this.vendorData.recommended_accepting_rules"></p>
      </mdb-col>
    </mdb-row>
    <mdb-row class="justify-content-center">
      <mdb-col col="7">
        <mdb-btn
          :disabled="!check2"
          class="mt-3 mb-3"
          :style="color"
          block
          @click="handleSend"
          >{{ vendorData.recommended_join }}</mdb-btn
        >
        <h6>{{ vendorData.recommend_button_text }}</h6>
      </mdb-col>
    </mdb-row>
    <mdb-row class="justify-content-center mt-5">
      <mdb-col id="rules" col="12">
        <div v-html="vendorData.rules_header" />
      </mdb-col>
    </mdb-row>
    <mdb-row class="justify-content-center mt-5">
      <mdb-col id="rules" col="12">
        <RulesAndPolicy :content="vendorData" />
      </mdb-col>
    </mdb-row>
  </div>
</template>
<script>
import { mdbRow, mdbCol, mdbInput, mdbBtn } from "mdbvue";
import RulesAndPolicy from "@/components/RulesAndPolicy.vue";
import helper from "@/mixins/helper";
import api from "@/mixins/api";
export default {
  name: "ValitutPalatRecommended",
  mixins: [helper, api],
  components: {
    mdbRow,
    mdbCol,
    mdbInput,
    mdbBtn,
    RulesAndPolicy,
  },
  props: {
    vendorData: Object,
    transactionData: Object,
  },
  data() {
    return {
      transactionDataLocal: this.transactionData,
      VendorDataLocal: this.vendorData,
      color: { "background-color": this.vendorData.color },
      txObject: {
        transactionId: this.transactionData.id,
        email: this.transactionData.recommended,
        recommendedId: this.transactionData.recommendedId,
        firstname: "",
        lastname: "",
        image: this.vendorData.recommended_thankyou_image,
        subject: this.vendorData.recommended_thankyou_subject,
        text: this.vendorData.recommended_thankyou,
      },
      check2: false,
    };
  },
  methods: {
    checkInput() {
      if (
        this.txObject.email.length > 0 &&
        this.txObject.firstname.length > 0 &&
        this.txObject.lastname.length > 0
      )
        return true;
      else return false;
    },
    handleSend() {
      if (this.checkInput()) this.sendTransaction();
      else
        this.$notify.error({
          message: this.$t("basicform.form_fill_all"),
          position: "top right",
          timeOut: this.$store.state.notifytimeoutshort,
        });
    },
    async sendTransaction() {
      this.closeTransaction(this.txObject).then((res) => {
        switch (res) {
          case 406:
            this.$notify.error({
              message: this.$t("basicform.transaction_closed"),
              position: "top right",
              timeOut: this.$store.state.notifytimeoutshort,
            });
            break;
          case 404:
            this.$notify.error({
              message: this.$t("basicform.form_not_sent"),
              position: "top right",
              timeOut: this.$store.state.notifytimeoutshort,
            });
            break;
          case 200:
            this.$notify.success({
              message: this.$t("basicform.form_sent"),
              position: "top right",
              timeOut: this.$store.state.notifytimeoutshort,
            });
            setTimeout(
              function () {
                this.$store.commit(
                  "updateThankYouText",
                  this.vendorData.recommended_thankyou_page
                );
                this.$router.push({
                  path: "/thankyou",
                });
              }.bind(this),
              1000
            );
            break;
        }
      });
    },
  },
  mounted() {},
};
</script>
<style scoped>
.md-form.md-outline {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
</style>
